.buy {
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buy h2 {
  text-align: center;
  color: rgb(15, 15, 165);
}

.buy .cards {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  width: 70%;
}

.buy .cards .card {
  background-color: #fff;
  box-shadow: 1px 1px 1px rgb(1, 1, 51);
  padding-bottom: 2rem;
  border-radius: 1rem;
}

.buy .cards .card div {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.buy .cards .card div h3 {
  font-size: 1.5rem;
  text-align: center;
}

.buy .cards .card img {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 300px;
}

.buy .cards .card div p {
  text-align: center;
  margin-bottom: 1.8rem;
  color: rgb(78, 113, 216);
  font-style: italic;
}

.buy .cards .card div button {
  border: none;
  padding: 1.2rem 0;
  background-color: blue;
  cursor: pointer;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
}

.buy .cards .card div button:hover {
  opacity: 0.5;
}

.buy .cards .card div button:active {
  opacity: 0.2;
}
