/* Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: navy;
  color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
}

.header .logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header .logo h2 {
  margin: 0;
  font-size: 24px;
}

.mobile-header {
  display: none;
}

.logo {
  font-size: 1.5em;
}

.nav {
  display: flex;
}

.nav a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

/* Media query for responsive design */
@media (max-width: 800px) {
  .header {
    display: none;
  }
  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 1rem;
    background-color: navy;
    color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
  }

  .mobile-header .logo {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mobile-header .logo h2 {
    margin: 0;
    font-size: 24px;
  }
  .nav {
    margin-top: 10px;
  }

  .nav a {
    margin: 10px;
    background-color: lightblue;
    color: rgb(55, 10, 171);
    font-size: 11px;
    padding: 5px;
    border-radius: 10px;
    font-weight: 700;
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    background-color: lightgrey;
    padding: 1rem;
    gap: 20px;
    position: absolute;
    top: 10px;
    right: 5px;
  }
  .mobile-nav a {
    color: blue;
    text-decoration: none;
    margin: 0 10px;
  }
  .close-button {
    color: black;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 30px;
  }
}
