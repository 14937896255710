.footer {
  background-color: navy;
  padding: 0.2rem;
  /* position: fixed;
  bottom: 0;
  left: 0;
  right: 0; */
}
.award-icon {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footer p {
  text-align: center;
  color: rgb(242, 238, 230);
}
