.banner {
  padding: 6rem;
  background: linear-gradient(to bottom, #0072ff, #00c6ff);
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.banner h2 {
  font-size: 4.5rem;
  color: white;
  width: 40rem;
}

.banner p {
  font-size: 1.2rem;
  color: rgb(243, 241, 241);
  width: 40rem;
}

.banner button {
  padding: 1rem;
  border: none;
  border-radius: 10rem;
  color: blue;
  margin-top: 2rem;
  cursor: pointer;
  font-weight: bold;
}

@media (max-width: 800px) {
  .banner {
    align-items: center;
    padding: 6rem 2rem;
    display: flex;
    flex-direction: column;
  }
  .banner img {
    width: 100%;
  }
  .banner h2,
  .banner p {
    width: 100%;
    text-align: center;
  }
  .banner p {
    font-size: 1rem;
  }
  .banner button {
    padding: 1rem;
    font-size: 2rem;
  }
  .banner h2 {
    font-size: 1.5rem;
  }
}
