.review {
  background-color: blue;
  padding: 4rem 2rem;
  height: 100vh;
}

.review h2 {
  color: white;
  text-align: center;
}

.review-container {
  background: linear-gradient(to bottom, #0072ff, #00c6ff);
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 2rem;
  z-index: 0;
}

.review-content {
  width: 100%;
}

.review-content h3,
.review-content p,
.review-content small {
  text-align: center;
}

.review-content h3 {
  display: flex;
  flex-direction: column;
  gap: 7px;
  font-size: 2rem;
}

.review-content h3 small {
  font-size: 0.8rem;
  font-weight: normal;
}

.review-content p {
  font-style: italic;
  font-size: 1.3rem;
  color: blue;
}

@media (max-width: 800px) {
  .review {
    height: 100%;
    padding-top: 3.4rem;
  }
}
