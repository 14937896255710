.about {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding: 6rem;
}

.about .container {
  display: flex;
  gap: 3rem;
}

.about h2 {
  font-size: 2rem;
}
.about p {
  font-size: 0.8rem;
}
.about .container div {
  gap: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.about img {
  border-radius: 0.5rem;
}
.about button {
  padding: 1rem;
  background-color: blue;
  border: none;
  color: white;
  border-radius: 5rem;
  cursor: pointer;
}

@media (max-width: 800px) {
  .about {
    padding: 3rem;
    text-align: center;
  }
  .about .container {
    flex-direction: column;
  }

  .about .container div {
    /* align-items: center; */
    display: block;
  }

  .about img {
    width: 100%;
  }
}
