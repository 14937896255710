.verify {
  background: linear-gradient(to right bottom, #00c6ff, #0072ff);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

.verify h2 {
  font-size: 2rem;
}

.verify p {
  color: blue;
  font-size: 1.3rem;
}

.verify-cards {
  background-color: #fff;
  padding: 3rem;
  margin-top: 2rem;
  border-radius: 2rem;
  align-items: center;
  margin: 2rem;
  display: flex;
  flex-direction: column;
}

.verify-cards div {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-bottom: 2rem;
}

.verify-cards div input {
  width: 40rem;
  padding: 0.7rem;
  font-size: 1rem;
  border-radius: 1rem;
  border: 1px solid grey;
}

.verify-cards div select {
  width: 41rem;
  padding: 0.7rem;
  font-size: 1rem;
  border-radius: 1rem;
  border: 1px solid grey;
}

.verify-cards p {
  color: #0072ff;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.verify-cards button {
  padding: 1rem 18rem;
  border: none;
  color: white;
  background-color: blue;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 2rem;
}

@media (max-width: 800px) {
  .verify {
    padding: 3rem 1rem;
  }
  .verify-cards {
    align-items: flex-start;
    margin: 0.2rem;
    padding: 1.7rem;
  }
  .verify-cards div select,
  .verify-cards div input,
  .verify-cards button,
  .verify-cards p {
    width: 250px;
  }
  .verify-cards button {
    padding: 1rem 0;
  }
}
